import React, { startTransition, useState } from 'react';
import ReportCard from '@src/pages/reports/ReportCard.tsx';
import ChevronButton from '@src/components/ChevronButton.tsx';
import {
  getDateFullInfo,
  getNextDate,
  getPreviousDate,
} from '@src/util/functions.ts';
import moment from 'moment';
import ReportCardStatement from '@src/pages/reports/ReportCardStatement.tsx';
import { REPORT_CATEGORY, REPORT_TERM } from '@src/util/constants.ts';
import ReportAdditionalDetail from '@src/pages/reports/ReportAdditionalDetail.tsx';
import { useDashboardAPI } from '@src/util/hooks.ts';
import { Moment } from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';

interface ReportAdditionalContentProps {
  term: REPORT_TERM;
  category: REPORT_CATEGORY;
}

const ReportAdditionalContent: React.FC<ReportAdditionalContentProps> = ({
  category,
  term,
}) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  let type = queryParams.get('type');
  if (!type) {
    type = category === REPORT_CATEGORY.LABOR ? 'employee' : 'statement';
  }

  const navigate = useNavigate();
  const setType = (value: string) => {
    queryParams.set('type', value);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  };

  const [date, setDate] = useState<Moment>(moment().subtract(1, 'days'));
  const { dashboardData } = useDashboardAPI(date, term);

  return (
    <div className={'flex-column-flex-start-center'}>
      <ReportCard
        containerStyle={{ maxWidth: '786px', width: '100%' }}
        cardStyle={{ maxWidth: '584px', width: '100%' }}
      >
        <div className={'report-card-additional-header'}>
          <ChevronButton
            direction={'left'}
            onClick={() => {
              startTransition(() => {
                setDate(getPreviousDate(date, term));
              });
            }}
          />
          <p className="text-base">{getDateFullInfo(date, term)}</p>
          {!getNextDate(date, term).isAfter(moment()) ? (
            <ChevronButton
              direction={'right'}
              onClick={() => {
                startTransition(() => {
                  setDate(getNextDate(date, term));
                });
              }}
            />
          ) : (
            <div style={{ width: '24px', height: '24px' }}></div>
          )}
        </div>
        <ReportCardStatement
          data={dashboardData}
          activeHeaderTab={term}
          activeCardHeaderTab={category}
        />
      </ReportCard>
      {category !== REPORT_CATEGORY.TOTAL && dashboardData && (
        <div
          className={'flex-column-flex-start-center'}
          style={{ width: '100%' }}
        >
          <ReportAdditionalDetail
            category={category}
            type={type}
            setType={setType}
            data={dashboardData}
          />
        </div>
      )}
    </div>
  );
};

export default ReportAdditionalContent;
