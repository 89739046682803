import { useEffect, useState } from 'react';
import Input from '@src/components/Input.tsx';
import { API_URLS_ADMIN, COLORS } from '@src/util/constants.ts';
import Snackbar from '@src/components/SnackBar.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useBranchAdmin } from '@src/util/adminHooks.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  IApiError,
  IApiPOSTSuccess,
  IBranchAdminDetailApiParams,
} from '@src/util/interfaces.ts';
import apiAdmin from '@src/util/apiAdmin.ts';
import { z } from 'zod';
import { companyRegisterNumberRegex, phoneRegex } from '@src/util/functions.ts';
import Button from '@src/components/Button.tsx';
import BottomButtonGroup from '@src/components/BottomButtonGroup.tsx';

/**
 * TODO
 *  지점상세는 팝업으로 변경해야함.(PC 기준)
 *
 */

const BranchDetailAdminForm = z.object({
  admin_id: z.string().trim().min(1),
  name: z.string().trim().min(1, '담당자를 입력해 주세요.'),
  resident_number_1: z.string().length(3),
  resident_number_2: z.string().length(2),
  resident_number_3: z.string().length(5),
  phone_number: z.string().regex(phoneRegex, '전화번호가 유효하지 않습니다.'),
  branch: z.string().trim().min(1, '지점을 입력해 주세요.'),
});

const BranchDetailAdminFormServer = BranchDetailAdminForm.omit({
  resident_number_1: true,
  resident_number_2: true,
  resident_number_3: true,
  admin_id: true,
}).extend({
  resident_number: z
    .string()
    .regex(companyRegisterNumberRegex, '사업자 등록번호가 유효하지 않습니다.'),
});
type TBranchDetailAdminForm = z.infer<typeof BranchDetailAdminForm>;
type TBranchDetailAdminFormServer = z.infer<typeof BranchDetailAdminFormServer>;

const BranchDetailAdminContent = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const { data } = useBranchAdmin(null, '', 'name');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const flattenedData = data?.pages
    .flatMap((page) => page.data)
    .filter((item) => item.id === parseInt(id))[0];

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TBranchDetailAdminForm>({
    resolver: zodResolver(BranchDetailAdminForm),
    defaultValues: {
      admin_id: '',
      name: '',
      resident_number_1: '',
      resident_number_2: '',
      resident_number_3: '',
      phone_number: '',
      branch: '',
    },
  });

  useEffect(() => {
    if (flattenedData) {
      reset({
        admin_id: flattenedData?.admin_id,
        name: flattenedData?.name,
        resident_number_1: flattenedData?.resident_number.split('-')[0],
        resident_number_2: flattenedData?.resident_number.split('-')[1],
        resident_number_3: flattenedData?.resident_number.split('-')[2],
        phone_number: flattenedData?.phone_number,
        branch: flattenedData?.branch,
      });
    }
  }, [flattenedData, reset]);
  const { mutate, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TBranchDetailAdminFormServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.patch(API_URLS_ADMIN.MY_PAGE_DETAIL + `/${id}`, formData);
    },
  });

  const onSubmit: SubmitHandler<TBranchDetailAdminForm> = (data) => {
    const formData: IBranchAdminDetailApiParams = {
      name: data.name,
      branch: data.branch,
      resident_number: `${data.resident_number_1}-${data.resident_number_2}-${data.resident_number_3}`,
      phone_number: data.phone_number,
    };

    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries({ queryKey: ['branch_admin'] });
        setSnackBarType('info');
        setSnackBarContent('저장 되었습니다.');
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
        }, 3000);
      },
      onError: (error) => {
        console.log(error);
        setSnackBarType('error');
        setSnackBarContent(error?.response?.data?.message);
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
        }, 3000);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={'flex-column-flex-start-stretch'}
          style={{ gap: '32px', margin: '0 48px 48px 48px' }}
        >
          <p className={'text-md'}>지점 상세</p>
          <div className={'branch-detail-admin-info-list-container'}>
            <div
              className={'branch-detail-admin-info-container'}
              style={{ width: '600px' }}
            >
              <div className={'branch-detail-admin-info-container-row'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  지점
                </p>
                <Input
                  register={register('branch')}
                  isLoading={false}
                  inputMode={'text'}
                  type={'text'}
                  style={{ width: '300px' }}
                />
              </div>
              {errors.branch && (
                <p className={'error-message'} style={{ marginLeft: '104px' }}>
                  {errors.branch.message}
                </p>
              )}
            </div>
            <div
              className={'branch-detail-admin-info-container'}
              style={{ width: '600px' }}
            >
              <div className={'branch-detail-admin-info-container-row'}>
                <p
                  className={'text-regular-bold'}
                  style={{ width: '92px', whiteSpace: 'nowrap' }}
                >
                  사업자등록번호
                </p>
                <div
                  className={'branch-detail-admin-info-container-input-row'}
                  style={{ width: '300px' }}
                >
                  <Input
                    register={register('resident_number_1')}
                    isLoading={false}
                    inputMode={'numeric'}
                    type={'text'}
                    maxLength={3}
                  />
                  <p style={{ margin: '0 5px' }}>-</p>
                  <Input
                    register={register('resident_number_2')}
                    isLoading={false}
                    inputMode={'numeric'}
                    type={'text'}
                    maxLength={2}
                  />
                  <p style={{ margin: '0 5px' }}>-</p>
                  <Input
                    register={register('resident_number_2')}
                    isLoading={false}
                    inputMode={'numeric'}
                    type={'text'}
                    maxLength={5}
                  />
                </div>
              </div>
              {(errors.resident_number_1 ||
                errors.resident_number_2 ||
                errors.resident_number_3) && (
                <p className={'error-message'} style={{ marginLeft: '104px' }}>
                  사업자 번호를 입력해 주세요.
                </p>
              )}
            </div>
            <div
              className={'branch-detail-admin-info-container'}
              style={{ width: '600px' }}
            >
              <div className={'branch-detail-admin-info-container-row'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  ID
                </p>
                <Input
                  isLoading={false}
                  inputMode={'text'}
                  type={'text'}
                  style={{ width: '300px', backgroundColor: COLORS['gray0'] }}
                  value={flattenedData.admin_id}
                  disabled={true}
                />
              </div>
            </div>
            <div
              className={'branch-detail-admin-info-container'}
              style={{ width: '600px' }}
            >
              <div className={'branch-detail-admin-info-container-row'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  담당자
                </p>
                <Input
                  register={register('name')}
                  isLoading={false}
                  inputMode={'text'}
                  type={'text'}
                  style={{ width: '300px' }}
                />
              </div>
              {errors.name && (
                <p className={'error-message'} style={{ marginLeft: '104px' }}>
                  {errors.name.message}
                </p>
              )}
            </div>
            <div
              className={'branch-detail-admin-info-container'}
              style={{ width: '600px' }}
            >
              <div className={'branch-detail-admin-info-container-row'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  연락처
                </p>
                <Input
                  register={register('phone_number')}
                  isLoading={false}
                  inputMode={'numeric'}
                  type={'text'}
                  style={{ width: '300px' }}
                />
              </div>
              {errors.phone_number && (
                <p className={'error-message'} style={{ marginLeft: '104px' }}>
                  {errors.phone_number.message}
                </p>
              )}
            </div>

            <BottomButtonGroup
              left={
                <Button
                  text={'목록으로'}
                  type={'button'}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              }
              right={
                <Button
                  text={'저장하기'}
                  type={'submit'}
                  isLoading={isPending}
                />
              }
            />
          </div>
        </div>
      </form>
      <Snackbar
        message={snackBarContent}
        open={snackBarOpen}
        type={snackBarType}
      />
    </>
  );
};

export default BranchDetailAdminContent;
