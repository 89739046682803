import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import InputLabel from '@src/components/InputLabel.tsx';
import Select from '@src/components/Select.tsx';
import { useMutation } from '@tanstack/react-query';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import api from '@src/util/api.ts';
import {
  API_URL,
  cerpUsePupClCd,
  cerpUsePupClCdOptions,
  cvaDcumSbmsOrgnClCd,
  cvaDcumSbmsOrgnClCdOptions,
} from '@src/util/constants.ts';
import Modal from '@src/components/Modal.tsx';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@src/util/store.ts';
import { objectToQueryString } from '@src/util/functions.ts';
import CertificatesDescription from '@src/pages/certificates/CertificatesDescription';
import Button from '@src/components/Button.tsx';
import BottomWrapper from '@src/components/BottomWrapper.tsx';

interface CertificateFormContent4Props {}

/**
 *  납세증명 (국세완납증명)
 *    발급유형: englCvaAplnYn (한글증명 - N, 영문증명 - Y)
 *    사용목적: cerpUsePupClCd
 *    제출처: cvaDcumSbmsOrgnClCd
 *    주민등록번호 공개여부: resnoOpYn (비공개 - N, 공개 - Y)
 *
 */

const CertificateForm4 = z.object({
  englCvaAplnYn: z.enum(['N', 'Y']),
  cerpUsePupClCd: z.object({
    value: z.enum(cerpUsePupClCd),
    label: z.string(),
  }),
  cvaDcumSbmsOrgnClCd: z.object({
    value: z.enum(cvaDcumSbmsOrgnClCd),
    label: z.string(),
  }),
  resnoOpYn: z.enum(['Y', 'N']),
});

const CertificateForm4Server = CertificateForm4.omit({
  cerpUsePupClCd: true,
  cvaDcumSbmsOrgnClCd: true,
}).extend({
  company_id: z.number(),
  cerpUsePupClCd: z.string(),
  cvaDcumSbmsOrgnClCd: z.string(),
});

type TCertificateForm4 = z.infer<typeof CertificateForm4>;
type TCertificateForm4Server = z.infer<typeof CertificateForm4Server>;

const CertificateFormContent4: React.FC<CertificateFormContent4Props> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  console.log(user); // 개인이 요청상태 , 법인은 바로
  const { control, handleSubmit } = useForm<TCertificateForm4>({
    resolver: zodResolver(CertificateForm4),
    mode: 'onChange',
    defaultValues: {
      englCvaAplnYn: 'N',
      cerpUsePupClCd: { label: '기타', value: '기타' },
      cvaDcumSbmsOrgnClCd: { label: '기타', value: '기타' },
      resnoOpYn: 'N',
    },
  });

  const { mutate, isSuccess, error, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TCertificateForm4Server
  >({
    mutationFn: (formData) => {
      const queryParams = objectToQueryString(formData);
      return api.post(
        API_URL + `/v1/communication/document?${queryParams}`,
        formData,
      );
    },
  });

  const onSubmit: SubmitHandler<TCertificateForm4> = (data) => {
    const formData = {
      company_id: user?.selectedCompany?.id ?? 0,
      type: '국세완납증명',
      englCvaAplnYn: data.englCvaAplnYn,
      cvaDcumSbmsOrgnClCd: data.cvaDcumSbmsOrgnClCd.value,
      cerpUsePupClCd: data.cerpUsePupClCd.value,
      resnoOpYn: data.resnoOpYn,
    };

    mutate(formData, {
      onSuccess: (response) => {
        setModalOpen(true);
        console.log(response);
      },
      onError: (error) => {
        setModalOpen(true);
        console.log(error);
      },
    });
  };

  return (
    <>
      <form
        className="certificate-form-content"
        onSubmit={handleSubmit(onSubmit)}
      >
        <CertificatesDescription
          title={
            '납세증명서는 발급일 현재 기한연장·유예액을 제외하고는 다른 체납액이 없음을 증명하는 민원입니다.'
          }
          description={
            '납세증명서의 유효기간은 발급일로부터 30일입니다.\n홈택스에서 체납세금을 납부했음에도 납세증명서 발급이 불가할 경우에는 담당자에게 문의하시기 바랍니다.'
          }
        />
        <InputLabel text={'발급유형'}>
          <Controller
            name={'englCvaAplnYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'englCvaAplnYn'}
                options={[
                  { label: '한글증명', value: 'N' },
                  { label: '영문증명', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>
        <InputLabel text="사용목적">
          <Controller
            name="cerpUsePupClCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cerpUsePupClCd"
                  options={cerpUsePupClCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel text="제출처">
          <Controller
            name="cvaDcumSbmsOrgnClCd"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  name="cvaDcumSbmsOrgnClCd"
                  options={cvaDcumSbmsOrgnClCdOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </InputLabel>
        <InputLabel
          text={'주민등록번호 공개여부'}
          description={'비공개시 출력 예 : 881111-*******'}
        >
          <Controller
            name={'resnoOpYn'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioButton
                name={'resnoOpYn'}
                options={[
                  { label: '비공개', value: 'N' },
                  { label: '공개', value: 'Y' },
                ]}
                defaultValue={value}
                onChange={onChange}
              />
            )}
          />
        </InputLabel>
        <BottomWrapper>
          <Button text={'신청하기'} isLoading={isPending} type={'submit'} />
        </BottomWrapper>
      </form>
      <Modal
        isOpen={isModalOpen}
        onOk={() => {
          setModalOpen(false);
          if (isSuccess) {
            navigate('/certificates/issued', { replace: true });
          } else {
            navigate(-1);
          }
        }}
        title={
          !isSuccess
            ? '증명서 발급 실패'
            : user?.selectedCompany?.type === 'CORP'
              ? '증명서 발급 성공'
              : '증명서 발급 요청'
        }
        content={
          !isSuccess
            ? error?.response.data.message
            : user?.selectedCompany?.type === 'CORP'
              ? '증명서 발급이 완료되었습니다. \n 증명서 발급 내역 메뉴에서 다운로드 가능합니다.'
              : '증명서 발급 요청 되었습니다.'
        }
        okButton={'확인'}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default CertificateFormContent4;
