import { Dispatch, SetStateAction } from 'react';
import { IApiError, IApiPOSTSuccess } from '@src/util/interfaces.ts';
import { useTaxInvoiceFormStore, useUserStore } from '@src/util/store.ts';
import IconSVG from '@src/components/IconSVG.tsx';
import {
  API_URLS,
  COLORS,
  TAX_INVOICE_MENU_TYPE,
} from '@src/util/constants.ts';
import CustomDrawer from '@src/components/CustomDrawer.tsx';
import {
  formatDateToYYYYMMDD,
  formatDateToYYYYMMDDHypen,
  isNotEmpty,
} from '@src/util/functions.ts';
import { useMutation } from '@tanstack/react-query';
import api from '@src/util/api.ts';
import { TTaxInvoiceFormServerWrapper } from '@src/pages/tax-invoice/TaxInvoice.tsx';
import Button from '@src/components/Button.tsx';

interface TaxInvoiceDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCloseCallBack: () => void;
  onErrorCallBack: () => void;
}

const TaxInvoiceDetailDrawer: React.FC<TaxInvoiceDetailDrawerProps> = ({
  open,
  setOpen,
  onCloseCallBack,
  onErrorCallBack,
}) => {
  const { taxInvoiceForm } = useTaxInvoiceFormStore((state) => ({
    taxInvoiceForm: state.taxInvoiceForm,
  }));
  const [user] = useUserStore((state) => [state.user]);

  const { mutate, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TTaxInvoiceFormServerWrapper
  >({
    mutationFn: (formData) => {
      const company_id = user?.selectedCompany?.id;
      return api.post(API_URLS.ISSUE_TAX_INVOICE + `/${company_id}`, formData);
    },
  });

  const handleOk = () => {
    const etxivClsfCd = location.pathname.endsWith(
      TAX_INVOICE_MENU_TYPE.REGULAR,
    )
      ? '01'
      : '03';

    const formData: TTaxInvoiceFormServerWrapper = {
      issue: {
        etxivDmnrClsfCd: '01', // 사업자번호 쓰기.
        etxivClsfCd: etxivClsfCd, // 세금계산서
        emailYn: 'Y',
        etxivKndCd: taxInvoiceForm.etxivKndCd,
        dmnrTxprDscmNo: `${taxInvoiceForm.registrationNumberConsumer.registrationNumber1Consumer}${taxInvoiceForm.registrationNumberConsumer.registrationNumber2Consumer}${taxInvoiceForm.registrationNumberConsumer.registrationNumber3Consumer}`,
        dmnrPfbAdr: taxInvoiceForm.dmnrPfbAdr,
        dmnrRprsFnm: taxInvoiceForm.dmnrRprsFnm,
        dmnrTnmNm: taxInvoiceForm.dmnrTnmNm,
        dmnrBcNm: taxInvoiceForm.dmnrBcNm,
        dmnrItmNm: taxInvoiceForm.dmnrItmNm,
        dmnrMpbNo: taxInvoiceForm.dmnrMpbNo,
        dmnrMchrgEmlAdr: taxInvoiceForm.dmnrMchrgEmlAdr,
        wrtDt: formatDateToYYYYMMDD(taxInvoiceForm.wrtDt),
        lsatInfrBizSVOList: taxInvoiceForm.lsatInfrBizSVOList,
        recApeClCd: taxInvoiceForm.recApeClCd,
      },
    };

    if (isNotEmpty(taxInvoiceForm.dmnrMchrgEmlAdr2)) {
      formData.issue.dmnrMchrgEmlAdr2 = taxInvoiceForm.dmnrMchrgEmlAdr2;
    }

    const type = taxInvoiceForm.taxInvoiceTypeCd.value;
    let aggValue = '';
    if (etxivClsfCd === '01') {
      aggValue = (
        taxInvoiceForm.lsatInfrBizSVOList.reduce((sum, item) => {
          const value = parseFloat(item.lsatSplCft) || 0;
          return sum + value;
        }, 0) +
        taxInvoiceForm.lsatInfrBizSVOList.reduce((sum, item) => {
          const value = parseFloat(item.lsatTxamt) || 0;
          return sum + value;
        }, 0)
      ).toString();
    } else {
      aggValue = taxInvoiceForm.lsatInfrBizSVOList
        .reduce((sum, item) => {
          const value = parseFloat(item.lsatSplCft) || 0;
          return sum + value;
        }, 0)
        .toString();
    }

    if (type === 'csh') {
      formData.issue.csh = aggValue;
    } else if (type === 'chck') {
      formData.issue.chck = aggValue;
    } else if (type === 'note') {
      formData.issue.note = aggValue;
    } else if (type === 'crit') {
      formData.issue.crit = aggValue;
    }

    mutate(formData, {
      onSuccess: (res) => {
        console.log(res);
        onCloseCallBack();
      },
      onError: (err) => {
        console.log(err);
        onErrorCallBack();
      },
    });
  };

  return (
    <CustomDrawer open={open} setOpen={setOpen}>
      <div className={'industry-search-detail-drawer-container'}>
        <div className={'industry-search-detail-drawer-header'}>
          <span>
            {location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR) &&
              '세금'}
            계산서 발급 확인
          </span>
          <IconSVG
            fill={COLORS['main']}
            size={'24'}
            containerStyle={{
              cursor: 'pointer',
            }}
            iconSVG={'close'}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
        <div className={'industry-search-detail-drawer-body'}>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '140px' }}>
              공급받는자 등록번호
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {
                taxInvoiceForm.registrationNumberConsumer
                  .registrationNumber1Consumer
              }
              -
              {
                taxInvoiceForm.registrationNumberConsumer
                  .registrationNumber2Consumer
              }
              -
              {
                taxInvoiceForm.registrationNumberConsumer
                  .registrationNumber3Consumer
              }
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '140px' }}>
              공급받는자 상호
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {taxInvoiceForm.dmnrTnmNm}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '140px' }}>
              공급받는자 성명
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {taxInvoiceForm.dmnrRprsFnm}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p
              className={'text-regular-bold'}
              style={{ width: '140px', whiteSpace: 'normal' }}
            >
              작성일자
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {formatDateToYYYYMMDDHypen(taxInvoiceForm.wrtDt)}
            </p>
          </div>
          <div className={'industry-search-detail-drawer-item'}>
            <p className={'text-regular-bold'} style={{ width: '140px' }}>
              공급가액
            </p>
            <p className={'text-regular'} style={{ width: '200px' }}>
              {taxInvoiceForm.lsatInfrBizSVOList
                .reduce((sum, item) => {
                  const value = parseFloat(item.lsatSplCft) || 0;
                  return sum + value;
                }, 0)
                .toLocaleString()}
            </p>
          </div>
          {location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR) && (
            <div className={'industry-search-detail-drawer-item'}>
              <p className={'text-regular-bold'} style={{ width: '140px' }}>
                세액
              </p>
              <p className={'text-regular'} style={{ width: '200px' }}>
                {taxInvoiceForm.lsatInfrBizSVOList
                  .reduce((sum, item) => {
                    const value = parseFloat(item.lsatTxamt) || 0;
                    return sum + value;
                  }, 0)
                  .toLocaleString()}
              </p>
            </div>
          )}
          {location.pathname.endsWith(TAX_INVOICE_MENU_TYPE.REGULAR) && (
            <div className={'industry-search-detail-drawer-item'}>
              <p className={'text-regular-bold'} style={{ width: '140px' }}>
                합계금액
              </p>
              <p className={'text-regular'} style={{ width: '200px' }}>
                {(
                  taxInvoiceForm.lsatInfrBizSVOList.reduce((sum, item) => {
                    const value = parseFloat(item.lsatSplCft) || 0;
                    return sum + value;
                  }, 0) +
                  taxInvoiceForm.lsatInfrBizSVOList.reduce((sum, item) => {
                    const value = parseFloat(item.lsatTxamt) || 0;
                    return sum + value;
                  }, 0)
                ).toLocaleString()}
              </p>
            </div>
          )}
        </div>
        <div className={'industry-search-detail-drawer-footer'}>
          <Button
            customClassName={'industry-search-detail-drawer-footer-cancel'}
            onClick={() => {
              setOpen(false);
            }}
            // isLoading={isPending}
            disabled={isPending}
            text={'취소'}
          ></Button>
          <Button
            customClassName={'industry-search-detail-drawer-footer-ok'}
            onClick={handleOk}
            isLoading={isPending}
            text={'발급'}
          />
        </div>
      </div>
    </CustomDrawer>
  );
};

export default TaxInvoiceDetailDrawer;
