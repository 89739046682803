import IconSVG from '@src/components/IconSVG.tsx';
import {
  API_URLS_ADMIN,
  COLORS,
  FILE_ICONS,
  RECEIPT_PURPOSE_MENU,
  RECEIPT_STATUS_MENU,
} from '@src/util/constants.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadioButton from '@src/components/RadioButton.tsx';
import {
  convertToKST,
  formatFileSize,
  generateOptions,
  getFileExtension,
} from '@src/util/functions.ts';
import Input from '@src/components/Input.tsx';
import Icon from '@src/components/Icon.tsx';
import ReactDatePicker from '@src/components/DatePicker.tsx';
import Select from '@src/components/Select.tsx';
import Modal from '@src/components/Modal.tsx';
import apiAdmin from '@src/util/apiAdmin.ts';
import Snackbar from '@src/components/SnackBar.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useReceiptAdmin } from '@src/util/adminHooks.ts';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment/moment';
import {
  IApiError,
  IApiPOSTSuccess,
  IReceiptPatchApiParams,
} from '@src/util/interfaces.ts';
import { z } from 'zod';
import Button from '@src/components/Button.tsx';
import BottomButtonGroup from '@src/components/BottomButtonGroup.tsx';

const ReceiptDetailAdminForm = z.object({
  status: z.string(),
  reason: z.string(),
  business_name: z.string(),
  sup_amount: z.string(),
  tax_amount: z.string(),
  tot_amount: z.string(),
  tx_dt: z.date().nullable(),
  purpose: z.object({
    label: z.string(),
    value: z.string(),
  }),
});

const ReceiptDetailAdminFormServer = ReceiptDetailAdminForm.omit({
  tx_dt: true,
  sup_amount: true,
  tax_amount: true,
  tot_amount: true,
  purpose: true,
}).extend({
  tx_dt: z.string(),
  sup_amount: z.number(),
  tax_amount: z.number(),
  tot_amount: z.number(),
  purpose: z.string(),
});

type TReceiptDetailAdminForm = z.infer<typeof ReceiptDetailAdminForm>;
type TReceiptDetailAdminFormServer = z.infer<
  typeof ReceiptDetailAdminFormServer
>;

const ReceiptDetailAdminContent = () => {
  const { id = '' } = useParams<{
    id: string;
  }>();
  const { data } = useReceiptAdmin(null, null, '', 'name');
  const navigate = useNavigate();
  const flattenedData = data?.pages
    .flatMap((page) => page.data)
    .filter((item) => item.id === parseInt(id))[0];

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarContent, setSnackBarContent] = useState<string>('');
  const [snackBarType, setSnackBarType] = useState<'info' | 'error'>('info');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { control, register, setValue, handleSubmit } =
    useForm<TReceiptDetailAdminForm>({
      resolver: zodResolver(ReceiptDetailAdminForm),
      defaultValues: {
        business_name: flattenedData?.business_name,
        status: flattenedData?.status,
        reason: flattenedData?.reason,
        sup_amount: flattenedData?.sup_amount.toString(),
        tax_amount: flattenedData?.tax_amount.toString(),
        tot_amount: flattenedData?.tot_amount.toString(),
        tx_dt:
          flattenedData?.tx_dt === ''
            ? null
            : moment(flattenedData?.tx_dt, 'YYYYMMDD').toDate(),
        purpose: {
          label: flattenedData?.purpose,
          value: flattenedData?.purpose,
        },
      },
    });

  const { mutate, isPending } = useMutation<
    IApiPOSTSuccess,
    IApiError,
    TReceiptDetailAdminFormServer
  >({
    mutationFn: (formData) => {
      return apiAdmin.patch(API_URLS_ADMIN.RECEIPT + `/${id}`, formData);
    },
  });

  const onSubmit: SubmitHandler<TReceiptDetailAdminForm> = (data) => {
    const formData: IReceiptPatchApiParams = {
      tx_dt: data.tx_dt !== null ? moment(data.tx_dt).format('YYYYMMDD') : '',
      business_name: data.business_name,
      reason: data.reason,
      sup_amount: parseInt(data.sup_amount),
      tax_amount: parseInt(data.tax_amount),
      tot_amount: parseInt(data.tot_amount),
      status: data.status,
      purpose: data.purpose.value,
    };

    mutate(formData, {
      onSuccess: (response) => {
        console.log(response);
        queryClient.invalidateQueries({ queryKey: ['receiptAdmin'] });
        setSnackBarType('info');
        setSnackBarContent('저장 되었습니다.');
        setSnackBarOpen(true);
        setTimeout(() => {
          setSnackBarOpen(false);
        }, 3000);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={'flex-column-flex-start'}
          style={{ gap: '32px', margin: '0 48px 48px 48px' }}
        >
          <div
            className={'flex-row-space-between-center'}
            style={{ width: '950px' }}
          >
            <p className={'text-md'}>영수증 상세</p>
            <IconSVG
              iconSVG={'delete'}
              size={'24'}
              fill={COLORS['red']}
              onClick={() => setDeleteModalOpen(true)}
              containerStyle={{
                cursor: 'pointer',
              }}
            />
          </div>
          <div className={'flex-row'} style={{ gap: '60px' }}>
            <img
              src={flattenedData.url}
              alt="img"
              style={{
                width: '386px', // 104 + 386
                height: '100%',
                boxSizing: 'border-box',
              }}
            />
            <div className={'receipt-detail-admin-info-list-container'}>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  소속지점
                </p>
                <p className={'text-regular'}>{flattenedData.company.branch}</p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  사업자명
                </p>
                <p className={'text-regular'}>{flattenedData.company.name}</p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  사업자등록번호
                </p>
                <p className={'text-regular'}>
                  {flattenedData.company.resident_number}
                </p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  상태
                </p>
                <Controller
                  name={'status'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioButton
                      name={'status'}
                      options={generateOptions(
                        Object.values(RECEIPT_STATUS_MENU),
                        false,
                      )}
                      defaultValue={value}
                      onChange={onChange}
                      containerStyle={{ gap: '12px' }}
                      labelDivContainer={{ padding: '0' }}
                    />
                  )}
                />
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  상호
                </p>
                <Input
                  register={register('business_name')}
                  isLoading={false}
                  inputMode={'text'}
                  style={{ minWidth: '400px' }}
                />
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  영수증
                </p>
                <div className={'receipt-detail-icon-row-admin'}>
                  <Icon
                    iconSrc={
                      FILE_ICONS[
                        getFileExtension(
                          flattenedData.filename,
                        ) as keyof typeof FILE_ICONS
                      ]
                    }
                    style={{ width: '20px', height: '20px', cursor: 'text' }}
                    imgStyle={{ width: '20px', height: '20px' }}
                  />
                  <p
                    className={'text-regular'}
                    style={{
                      width: '244px',
                      boxSizing: 'border-box',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {flattenedData.filename} (
                    {formatFileSize(flattenedData.size)})
                  </p>
                </div>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  업로드 날짜
                </p>
                <p className={'text-regular'}>
                  {convertToKST(flattenedData.created_at)}
                </p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  영수증 날짜
                </p>
                <Controller
                  control={control}
                  name={'tx_dt'}
                  render={({ field: { value } }) => (
                    <ReactDatePicker
                      value={value}
                      onChange={(date) =>
                        setValue('tx_dt', date, { shouldValidate: true })
                      }
                    />
                  )}
                />
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  공급가액
                </p>
                <Input
                  register={register('sup_amount')}
                  isLoading={false}
                  inputMode={'numeric'}
                  type={'text'}
                  style={{ minWidth: '100px' }}
                />
                <p className={'text-regular'}>원</p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  세액
                </p>
                <Input
                  register={register('tax_amount')}
                  isLoading={false}
                  inputMode={'numeric'}
                  style={{ minWidth: '100px' }}
                  type={'text'}
                />
                <p className={'text-regular'}>원</p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  공급대가
                </p>
                <Input
                  register={register('tot_amount')}
                  isLoading={false}
                  inputMode={'numeric'}
                  style={{ minWidth: '100px' }}
                  type={'text'}
                />
                <p className={'text-regular'}>원</p>
              </div>
              <div className={'receipt-detail-admin-info-container'}>
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  구분
                </p>
                <Controller
                  name="purpose"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        name="purpose"
                        options={generateOptions(
                          Object.keys(RECEIPT_PURPOSE_MENU),
                          false,
                        )}
                        onChange={onChange}
                        value={value}
                        isFilterSearch={true}
                      />
                    );
                  }}
                />
              </div>

              <div
                className={'receipt-detail-admin-info-container'}
                style={{ marginBottom: '48px' }}
              >
                <p className={'text-regular-bold'} style={{ width: '92px' }}>
                  반려 사유
                </p>
                <Input
                  register={register('reason')}
                  isLoading={false}
                  inputMode={'text'}
                  style={{ minWidth: '400px' }}
                />
              </div>
              <BottomButtonGroup
                left={
                  <Button
                    text={'목록으로'}
                    type={'button'}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                }
                right={
                  <Button
                    text={'저장하기'}
                    type={'submit'}
                    isLoading={isPending}
                  />
                }
              />
            </div>
          </div>
        </div>
      </form>
      <>
        <Modal
          isOpen={deleteModalOpen}
          onOk={() => {
            setDeleteModalOpen(false);
            apiAdmin
              .delete(API_URLS_ADMIN.RECEIPT + `/${id}`)
              .then((res) => {
                console.log(res);
                setDeleteConfirmModalOpen(true);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          title={'영수증 삭제'}
          content={'영수증을 삭제하시겠습니까?'}
          okButton={'삭제'}
          cancelButton={'취소'}
          onCancel={() => setDeleteModalOpen(false)}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
        />
        <Modal
          isOpen={deleteConfirmModalOpen}
          onOk={() => {
            setDeleteConfirmModalOpen(false);
            queryClient.invalidateQueries({ queryKey: ['receiptAdmin'] });
            navigate(-1);
          }}
          title={'영수증 삭제'}
          content={'영수증이 삭제되었습니다.'}
          okButton={'확인'}
          onClose={() => {
            setDeleteConfirmModalOpen(false);
          }}
        />
        <Snackbar
          message={snackBarContent}
          open={snackBarOpen}
          type={snackBarType}
        />
      </>
    </>
  );
};

export default ReceiptDetailAdminContent;
